import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';

const BrandsList = (props) => (
    <List
        {...props}
        title="Brands"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={Permissions('co_branded_videos', 'create') === true}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField
                label="Name"
                source={source.name}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <EditButtonCustom key="edit-btn" resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default BrandsList;
