import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    ContentImagesInput,
    DurationInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ImageInput,
    ProductStatesSelect,
    RichTextInput,
    RoleTypeInput,
    StyledSelectArrayInput,
    TitleSlug,
    TagsInput,
} from '@src/custom';
import { status } from '@src/global/choices';
import { source } from './data';

import { helperText } from '@src/global/helperText';

import Moment from 'moment';

const TrainingForm = (props) => {
    const { isCreate, resource, record } = props;
    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <RoleTypeInput displayLeft source={source.roleType} />
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                required
                isCreate={isCreate}
                source={source}
                pageUrlPart="training-and-job-aids/elearnings"
            />
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TagsInput resource={resource} record={record} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <RichTextInput
                        label="Content"
                        source={source.content}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                        parse={(v) => Moment(v).toISOString()}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <ContentImagesInput
                        label="Expiration dates for embedded content images"
                        source={source.contentImages}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ImageInput source={source.image} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <DurationInput
                        fullWidth
                        label="Estimated Duration"
                        source={source.estimatedDuration}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="External eLearning URL"
                        source={source.externalUrl}
                        helperText={helperText.URL}
                    />
                </Grid>
            </Grid>

            {
                /*
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales &amp; Marketing Category"
                        source={source.salesAndMarketingCategories}
                        reference={source.salesAndMarketingCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
                */
            }

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="System Category"
                        source={source.systemCategories}
                        reference={source.systemCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Product Category"
                        source={source.productCategories}
                        reference={source.productCategoriesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales Channel"
                        source={source.salesChannels}
                        reference={source.salesChannelsReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        fullWidth
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

TrainingForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default TrainingForm;
