function mapGetOneTagData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListTagsData(responseData) {
    return responseData.map((tag) => ({
        ...tag,
    }));
}

export { mapGetOneTagData, mapGetListTagsData };
