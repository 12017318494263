const source = {
    name: 'name',
    note: 'note',
    position: 'position',
    color: 'color',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

const colorOptions = [
    { id: 'red', name: 'red' },
    { id: 'orange', name: 'orange' },
    { id: 'yellow', name: 'yellow' },
    { id: 'green', name: 'green' },
    { id: 'blue', name: 'blue' },
    { id: 'indigo', name: 'indigo' },
    { id: 'violet', name: 'violet' },
];

export {
    source,
    colorOptions,
};
