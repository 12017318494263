import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Permissions } from '@src/global/permissions';
import HistoryIcon from '@material-ui/icons/History';

import {
    CloneButton,
    TopToolbar,
    ListButton,
    DeleteButton,
    Button,
} from 'react-admin';

import { RevisionHistoryPopup } from '@src/custom';

const EditActions = (props) => {
    const { basePath, data, resource, className, hasList, hasClone = true } = props;
    const clonedData = data ?
        {
            ...data,
            status: 'draft',
            title: `${data.title} CLONED`,
            slug: `${data.slug}-cloned`,
        } :
        {};

    const [openRevisionsPopup, setOpenRevisionsPopup] = React.useState(false);
    const handleClick = () => setOpenRevisionsPopup(true);

    const handleClose = useCallback(() => {
        setOpenRevisionsPopup(false);
    }, [setOpenRevisionsPopup]);

    return (
        <TopToolbar key={`toptoolbar-${data ? data.slug : ''}`} className={className}>
            <>
                {
                    data &&
                    <>
                        {
                            Permissions(resource, 'delete') === true &&
                            <DeleteButton
                                key="delete-btn"
                                basePath={basePath}
                                mutationMode="pessimistic"
                                record={data}
                                resource={resource}
                            />
                        }
                        {
                            data.record_revisions && data.record_revisions.length > 0 && (
                                <Button
                                    key="revisions-btn"
                                    label="Revision History"
                                    onClick={handleClick}
                                >
                                    <HistoryIcon />
                                </Button>
                            )
                        }
                        {
                            Permissions(resource, 'create') === true && hasClone &&
                            <CloneButton
                                key="clone-btn"
                                basePath={basePath}
                                record={clonedData}
                            />
                        }
                    </>
                }
                { hasList && <ListButton key="list-btn" basePath={basePath} />}
                {
                    data && data.record_revisions && data.record_revisions.length > 0 &&
                        <RevisionHistoryPopup
                            key="revision-history-popup"
                            modalOpen={openRevisionsPopup}
                            recordRevisions={data.record_revisions}
                            handleClose={handleClose}
                        />
                }
            </>
        </TopToolbar>
    );
};

EditActions.propTypes = {
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    hasList: PropTypes.bool,
    data: PropTypes.object,
    resource: PropTypes.string,
    hasClone: PropTypes.bool,
};

export default EditActions;
