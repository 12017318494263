import React from 'react';
import PropTypes from 'prop-types';

import {
    Datagrid,
    FunctionField,
    Link,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import { BulkActionButtons, EditButtonCustom } from '@src/custom';

import { source } from './data';
import { categoriesListTitle } from './utils';

const CategoriesList = (props) => (
    <List
        {...props}
        title={categoriesListTitle(props.basePath)}
        sort={{ field: source.name, order: 'ASC' }}
        hasCreate={Permissions('categories', 'create') === true}
        bulkActionButtons={<BulkActionButtons resource="categories" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Title"
                source={source.name}
            />
            <FunctionField
                label="Associations Count"
                source={source.associationsCount}
                render={(record, source) => {
                    // eslint-disable-next-line camelcase
                    const filterParams = `filter=${JSON.stringify({ category_id: record.id })}`;

                    return (
                        <Link to={{ pathname: '/categorizations', search: filterParams }}>
                            {record[source]}
                        </Link>
                    );
                }}
            />
            <TextField
                label="Note"
                source={source.note}
            />
            {
                Permissions('categories', 'update') === true &&
                <EditButtonCustom key="edit-btn" {...props} />
            }
        </Datagrid>
    </List>
);

CategoriesList.propTypes = {
    basePath: PropTypes.string.isRequired,
};

export default CategoriesList;

