import { Taxonomies } from '@src/global/taxonomies';

export const source = {
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    contentImages: 'content_images',
    roleType: 'role_type',
    status: 'status',
    publishedAt: 'published_at',
    title: 'title',
    slug: 'slug',
    description: 'description',
    note: 'note',
    content: 'content.content',
    contentExpiredAt: 'content.expired_at',
    image: 'image',
    estimatedDuration: 'estimated_duration',
    externalUrl: 'external_url',
    categories: 'categories',
    salesAndMarketingCategories: 'salesAndMarketingCategories',
    salesAndMarketingCategoriesReference: `taxonomies/${Taxonomies.salesAndMarketing}/categories`,
    systemCategories: 'systemCategories',
    systemCategoriesReference: `taxonomies/${Taxonomies.systemAndTechnical}/categories`,
    productCategories: 'productCategories',
    productCategoriesReference: `taxonomies/${Taxonomies.productNewsAndUpdates}/categories`,
    salesChannelIds: 'sales_channel_ids',
    salesChannels: 'sales_channels',
    salesChannelsReference: 'sales_channels',
    productIds: 'product_ids',
    products: 'products',
    productsReference: 'products',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    expiredAt: 'expired_at',
    isHomeArticle: 'is_home_article',
    versionDescription: 'version_description',
    tags: 'tags',
    tagsReference: 'tags',
};
