const source = {
    id: 'id',
    name: 'name',
    associationsCount: 'associations_count',
    resources: 'resources',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

const resourceSource = {
    id: 'id',
    discardedAt: 'discarded_at',
    type: 'type',
    title: 'title',
};

export {
    source,
    resourceSource,
};
