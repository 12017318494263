import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapStatesForList,
    mapStatesForOne,
    mapTagsForOne,
} from './common';

function mapWritingCompaniesforOne(responseData) {
    return {
        // eslint-disable-next-line camelcase
        writing_companies: responseData.writing_companies.map((wc) => wc.id),
    };
}

function mapResourceDataForOne(responseData) {
    const resourceData = responseData.resource || {};
    return {
        resource: {
            ...resourceData,
            ...mapWritingCompaniesforOne(resourceData),
            ...mapTagsForOne(resourceData),
        },
    };
}

function mapGetOneGuideData(responseData) {
    return {
        ...responseData,
        ...mapAttachmentsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapResourceDataForOne(responseData),
        ...mapAuthorForOne(responseData),
        ...mapTagsForOne(responseData),
    };
}

function mapGetListGuidesData(responseData) {
    return responseData.map((data) => ({
        ...data,
        ...mapProductsForList(data),
        ...mapStatesForList(data),
        ...mapAttachmentsForOne(data),
    }));
}

export { mapGetOneGuideData, mapGetListGuidesData };

