import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    // ReferenceInput,
    SelectInput,
    DateInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
    source,
    requestStatuses,
} from './data';

const CollateralRequestForm = (props) => {
    const { resource, record } = props;

    const [currentTab, setCurrentTab] = useState(0);
    // const [rts, setRts] = useState(record.crts);

    const handleTabChange = useCallback((event, tabId) => {
        setCurrentTab(tabId);
    }, [setCurrentTab]);

    const templateName = (crt) => {
        const { record } = props;
        const tName = record.collateral_templates.filter((ct) =>
            crt.collateral_template_id === ct.id,
        )[0].title;
        const name = `${tName} #${crt.id}`;
        return name;
    };

    return (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <DateInput
                        required
                        label="Date Requested"
                        source={source.requestedAt}
                    />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Date Completed"
                        source={source.completedAt}
                    />
                </Grid>
                <Grid item>
                    <SelectInput
                        label="Status"
                        source={source.status}
                        choices={requestStatuses}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="First Name"
                        source={source.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Last Name"
                        source={source.lastName}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12} md={6}>
                    <TextInput
                        fullWidth
                        required
                        label="Email"
                        source={source.email}
                    />
                </Grid>
            </Grid>

            {
                record.collateral_request_templates && record.collateral_request_templates.length > 0 && (
                    <>
                        <Grid item>
                            <Tabs
                                value={currentTab}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="icon label tabs"
                                onChange={handleTabChange}
                            >
                                {
                                    record.collateral_request_templates.map((crt) => {
                                        const name = templateName(crt);

                                        return (
                                            <Tab key={crt.id} label={name} />
                                        );
                                    })
                                }
                            </Tabs>
                        </Grid>
                        <Grid container>
                            {
                                // eslint-disable-next-line complexity
                                record.collateral_request_templates.map((crt, index) => {
                                    const name = templateName(crt);

                                    return (
                                        <Grid
                                            key={`tab-panel-${crt.id}`}
                                            item
                                            xs={12}
                                            md={12}
                                            value={index}
                                            index={index}
                                            hidden={index !== currentTab}
                                        >
                                            <h3>{ `${name} Customization Options` }</h3>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </>
                )
            }
        </>
    );
};

CollateralRequestForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default CollateralRequestForm;
