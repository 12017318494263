import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
    input: {
        display: 'none',
    },
});

const ImageDropzone = (props) => {
    const { children, onFileSelected } = props;

    const classes = useStyles();

    const handleDrop = React.useCallback((files) => {
        onFileSelected(files[0]);
    }, [onFileSelected]);

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: {
            'image/*': ['.jpg', '.jpeg', '.png'],
        },
    });

    return (
        <div {...getRootProps({ id: 'direct-upload-dropzone', className: classes.root })}>
            <input {...getInputProps({ className: classes.input })} />
            { children }
        </div>
    );
};

ImageDropzone.propTypes = {
    children: PropTypes.node.isRequired,
    onFileSelected: PropTypes.func.isRequired,
};

export default ImageDropzone;
