// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

import React from 'react';

import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import 'whatwg-fetch';
// import 'promise-polyfill/src/polyfill';
import 'nodep-date-input-polyfill';

import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ImageSelector from '@src/components/image-selector';
import Error403 from '@src/components/error/Error403';
import Login from '@src/containers/login';
import App from '@src/containers/app';
import { GetSignedUrlFile } from '@src/custom';

import './css/metlife_css/index.scss';
import './index.scss';

const SentryRoute = Sentry.withSentryRouting(Route);

if (process.env.SENTRY_DSN) {
    const history = createBrowserHistory();

    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],

        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
        tracesSampleRate: 0.3,
    });
}

render(
    <Router>
        <Switch>
            <SentryRoute exact path="/attachments/:id/download" component={GetSignedUrlFile} />
            <SentryRoute exact path="/forbidden" component={Error403} />,
            <SentryRoute exact path="/login" component={Login} />,
            <SentryRoute exact path="/image-selector" component={ImageSelector} />,
            <SentryRoute component={App} />
        </Switch>
    </Router>,
    document.querySelector('#root')
);
