import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles({
    root: {
        color: '#999',
        cursor: 'pointer',
        padding: '1rem',
        background: '#efefef',
        textAlign: 'center',
    },
    input: {
        display: 'none',
    },
    dropzoneMessage: {
        fontSize: '16px',
    },
});

const VideoUploaderDropzone = ({ onFileSelected }) => {
    const classes = useStyles();

    const handleDrop = React.useCallback((files) => {
        onFileSelected(files[0]);
    }, [onFileSelected]);

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
    });

    return (
        <div {...getRootProps({ id: 'direct-upload-dropzone', className: classes.root })}>
            <input {...getInputProps({ className: classes.input })} />
            <Typography
                className={classes.dropzoneMessage}
                variation="body1"
                align="center"
                color="textSecondary"
            >
                Drop a file to upload, or click to select it.
            </Typography>
        </div>
    );
};

VideoUploaderDropzone.propTypes = {
    onFileSelected: PropTypes.func.isRequired,
};

export default VideoUploaderDropzone;
