import { getColorOptions } from './colors';

// These styles are included only in the editor iframe
// extract-loader and file-loader are required to get link to this css file
import editorStyles from '!file-loader?name=[name].[hash].css!extract-loader!css-loader!sass-loader!@src/css/editor/editor.scss';

export const EditorConfig = {
    /* eslint-disable camelcase */
    versionCheck: false,
    allowedContent: true,
    autoGrow_bottomSpace: 50,
    autoGrow_minHeight: 200,
    autoGrow_maxHeight: 600,
    colorButton_colors: getColorOptions(),
    colorButton_foreStyle: {
        element: 'span',
        attributes: { class: 'text-#(colorName)' },
    },
    colorButton_enableAutomatic: false,
    colorButton_backStyle: {
        element: 'span',
        attributes: { class: 'background-#(colorName)' },
    },
    indentClasses: [
        'indent-01',
        'indent-02',
        'indent-03',
        'indent-04',
        'indent-05',
        'indent-06',
        'indent-07',
        'indent-08',
        'indent-09',
        'indent-10',
        'indent-11',
        'indent-12',
    ],
    // Use the classes 'AlignLeft', 'AlignCenter', 'AlignRight', 'AlignJustify'
    justifyClasses: ['text-left', 'text-center', 'text-right', 'text-justify'],
    contentsCss: editorStyles,
    extraPlugins: [
        'autogrow',
        'indentblock',
        'justify',
        'colorbutton',
        'panelbutton',
        'floatpanel',
        'layoutmanager',
        'table',
        'tabletools',
        'tableresize',
        'dialog',
        'bootstrapTabs',
        'scriptLoader',
    ],
    layoutmanager_loadbootstrap: false,
    stylesSet: 'mlStyles',
    filebrowserImageBrowseUrl: '/image-selector',
    toolbar: [
        { name: 'tools', items: ['Maximize'] },
        { name: 'styles', items: ['Format', 'Styles'] },
        { name: 'paragraph', items: ['AddLayout', 'BootstrapTabs', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'Underline', 'Subscript', 'Superscript', 'RemoveFormat', 'TextColor', 'BGColor'] },
        { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
        { name: 'document', items: ['Source'] },
    ],
    /* eslint-enable camelcase */
};
