function mapGetOneAgencyData(responseData) {
    return {
        ...responseData,
    };
}

function mapGetListAgenciesData(responseData) {
    return responseData.map((data) => ({
        ...data,
    }));
}

export { mapGetOneAgencyData, mapGetListAgenciesData };
