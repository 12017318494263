import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import { CKEditor } from 'ckeditor4-react';
import { InputHelperText, Labeled, useInput } from 'react-admin';

import { EditorConfig } from './EditorConfig';

const MlStyles = [
    { name: 'Primary link', element: 'a', attributes: { class: 'ml-link' } },
    { name: 'Primary button link', element: 'a', attributes: { class: 'ml-button-primary' } },
    { name: 'Secondary button link', element: 'a', attributes: { class: 'ml-button-secondary' } },
    { name: 'Table', element: 'table', attributes: { class: 'ml-table-alt-rows' } },
    { name: 'Table odd row', element: 'tr', attributes: { class: 'odd' } },
    { name: 'Table even row', element: 'tr', attributes: { class: 'even' } },
    { name: 'Table caption', element: 'caption', attributes: { class: 'ml-table-title' } },
];

const RichTextInput = (props) => {
    const { defValue } = props;
    const input = useInput(props);

    const {
        input: { name, onChange, onBlur, onFocus },
        meta: { error, touched },
    } = input;

    const inputWithRequire = {
        ...input,
        isRequired: Boolean(props.required),
    };

    const handleBeforeLoad = useCallback((CKEDITOR) => {
        if (!CKEDITOR.stylesSet.registered.mlStyles) {
            CKEDITOR.stylesSet.add('mlStyles', MlStyles);
        }

        if (!CKEDITOR.plugins.registered.scriptLoader) {
            CKEDITOR.plugins.addExternal('scriptLoader', '/lib/ckeditor/plugins/scriptLoader/');
        }

        if (!CKEDITOR.plugins.registered.layoutmanager) {
            CKEDITOR.plugins.addExternal('layoutmanager', '/lib/ckeditor/plugins/layoutmanager/');
        }

        if (!CKEDITOR.plugins.registered.basewidget) {
            CKEDITOR.plugins.addExternal('basewidget', '/lib/ckeditor/plugins/basewidget/');
        }

        if (!CKEDITOR.plugins.registered.table) {
            CKEDITOR.plugins.addExternal('table', '/lib/ckeditor/plugins/table/');
        }

        if (!CKEDITOR.plugins.registered.tabletools) {
            CKEDITOR.plugins.addExternal('tabletools', '/lib/ckeditor/plugins/tabletools/');
        }

        if (!CKEDITOR.plugins.registered.dialog) {
            CKEDITOR.plugins.addExternal('dialog', '/lib/ckeditor/plugins/dialog/');
        }

        if (!CKEDITOR.plugins.registered.bootstrapTabs) {
            CKEDITOR.plugins.addExternal('bootstrapTabs', '/lib/ckeditor/plugins/bootstrapTabs/');
        }
    }, []);

    const handleChange = useCallback((event) => {
        const data = event.editor.getData();
        onChange(data);
    }, [onChange]);

    const handleInstanceReady = useCallback((CKEDITOR) => {
        const { input: { value } } = input;

        CKEDITOR.editor.setData(value);
    }, [input]);

    return (
        <Labeled {...props} {...inputWithRequire}>
            <>
                <CKEditor
                    config={EditorConfig}
                    initData={defValue}
                    name={name}
                    onChange={handleChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onBeforeLoad={handleBeforeLoad}
                    onInstanceReady={handleInstanceReady}
                />
                <FormHelperText
                    error={Boolean(error)}
                >
                    <InputHelperText
                        error={error}
                        touched={touched}
                    />
                </FormHelperText>
            </>
        </Labeled>
    );
};

RichTextInput.propTypes = {
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    label: PropTypes.string,
    defValue: PropTypes.string,
    margin: PropTypes.string,
    source: PropTypes.string,
    resource: PropTypes.string,
    required: PropTypes.bool,
};

RichTextInput.defaultProps = {
    fullWidth: true,
    margin: 'dense',
};

export default React.memo(RichTextInput);
