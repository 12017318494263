const source = {
    authorName: 'authorName',
    authorEmail: 'authorEmail',
    authorId: 'author_id',
    status: 'status',
    publishedAt: 'published_at',
    title: 'title',
    description: 'description',
    externalUrl: 'external_url',
    salesChannelIds: 'sales_channel_ids',
    salesChannels: 'sales_channels',
    salesChannelsReference: 'sales_channels',
    productIds: 'product_ids',
    products: 'products',
    productsReference: 'products',
    stateIds: 'state_ids',
    states: 'states',
    statesReference: 'states',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    expiredAt: 'expired_at',
    isHomeArticle: 'is_home_article',
    versionDescription: 'version_description',
    tags: 'tags',
    tagsReference: 'tags',
};

export {
    source,
};
