import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreate,
    useNotify,
} from 'react-admin';

import { useForm } from 'react-final-form';

import { Permissions } from '@src/global/permissions';

const TagsInput = (props) => {
    const { resource, record } = props;
    const [tagIds, setTagIds] = useState(record.tags);

    const form = useForm();
    const [create] = useCreate();
    const notify = useNotify();

    const handleChange = useCallback((ids) => {
        setTagIds(ids);
    }, []);

    const handleCreate = useCallback((value) => {
        create(
            'tags',
            {
                name: value,
            },
            {
                onSuccess: ({ data }) => {
                    const newIds = [...tagIds, data.id];
                    setTagIds(newIds);
                    form.change('tags', newIds);
                },
                onFailure: () => {
                    let errorMsg = 'Name has already been taken';
                    if (Permissions('tags', 'create') !== true) {
                        errorMsg = 'You don\'t have permission to create tags';
                    }

                    notify(errorMsg, 'error');
                },
            },
        );
    }, [create, notify, form, tagIds]);

    return (
        <ReferenceArrayInput
            fullWidth
            label="Tags"
            reference="tags"
            resource={resource}
            source="tags"
            onChange={handleChange}
        >
            <AutocompleteArrayInput
                shouldRenderSuggestions={(val) => {
                    return val.trim().length > 1;
                }}
                onCreate={handleCreate}
            />
        </ReferenceArrayInput>

    );
};

TagsInput.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
};

export default TagsInput;
