import {
    cleanCommonParams,
    mapCollateralRequestTemplatesParams
} from './common';

const mapCollateralRequestParams = (
    isCreate,
    {
        // eslint-disable-next-line camelcase
        collateral_request_templates,
        ...params
    },
) => {
    return new Promise((resolve) => {
        resolve({
            // eslint-disable-next-line camelcase
            collateral_request: {
                ...cleanCommonParams(params),
                ...mapCollateralRequestTemplatesParams(isCreate, collateral_request_templates),
            },
        });
    });
};

export { mapCollateralRequestParams };
