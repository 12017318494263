import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles({
    buttonIcon: {
        marginRight: '0.5em',
        fontSize: '18px',
    },
});

const ImageUploaderActions = ({ isFileSelected, isUploading, onCancel, onSave }) => {
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
                <Button
                    disabled={!isFileSelected || isUploading}
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                >
                    <CloudUploadIcon className={classes.buttonIcon} />
                    Upload
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={onCancel}>
                    <CancelIcon className={classes.buttonIcon} />
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
};

ImageUploaderActions.propTypes = {
    isFileSelected: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default React.memo(ImageUploaderActions);
