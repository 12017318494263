import React from 'react';
import PropTypes from 'prop-types';

import {
    BooleanInput,
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import { status } from '@src/global/choices';
import { source } from './data';

import {
    AttachmentsInput,
    ContentImagesInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ImageInput,
    ProductStatesSelect,
    RichTextInput,
    StyledSelectArrayInput,
    TitleSlug,
    TagsInput,
} from '@src/custom';

import Moment from 'moment';

const FieldManagementNewsForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                key="title-slug"
                required
                source={source}
                pageUrlPart="field-management/field-management-news"
                {...props}
            />
            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TagsInput resource={resource} record={record} />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <RichTextInput
                        required
                        label="Content"
                        source={source.content}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DateInput
                        label="Content Expiration Date"
                        source={source.contentExpiredAt}
                        parse={(v) => Moment(v).toISOString()}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <ContentImagesInput
                        label="Expiration dates for embedded content images"
                        source={source.contentImages}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <ImageInput source={source.image} />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12}>
                    <BooleanInput
                        label="Featured on Category Overview Page?"
                        source={source.isFeatured}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <AttachmentsInput
                        fullWidth
                        source={source.attachments}
                        label="Attachments"
                        accept={{
                            'application/pdf': ['.pdf'],
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-categories"
                        required
                        label="Category"
                        reference={source.categoriesReference}
                        resource={resource}
                        source={source.categories}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-sales-channels"
                        required
                        label="Sales Channel"
                        reference={source.salesChannelsReference}
                        resource={resource}
                        source={source.salesChannels}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        key="select-array-input-products"
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        key="select-array-input-states"
                        fullWidth
                        required
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>

            </Grid>
        </>
    );
};

FieldManagementNewsForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default FieldManagementNewsForm;
