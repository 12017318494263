import React from 'react';
import PropTypes from 'prop-types';

import { Permissions } from '@src/global/permissions';

import { makeStyles } from '@material-ui/core/styles';
import {
    DateInput,
    TextField,
    useRecordContext,
    Labeled,
} from 'react-admin';

import Moment from 'moment';

const useStyles = makeStyles((_theme) => ({
    label: {
        paddingTop: '6px',
        paddingBottom: 0,
    },
    field: {
        top: '-8px',
        position: 'relative',
        fontSize: '1em',
    },
}));

const PublicationDateInput = (props) => {
    const { source, resource } = props;
    const record = useRecordContext();
    const classes = useStyles();

    if (Permissions(resource, 'set_published') === false) {
        if (record && record.published_at) {
            return (
                <Labeled filled="true" label="Publication Date" className={classes.label}>
                    <TextField
                        className={classes.field}
                        source={source.published_at}
                    />
                </Labeled>
            );
        }

        return null;
    }

    return (
        <DateInput
            required
            label="Publication Date"
            source={source.publishedAt}
            parse={(v) => Moment(v).toISOString()}
        />
    );
};

PublicationDateInput.propTypes = {
    resource: PropTypes.string,
    source: PropTypes.string,
};

export default PublicationDateInput;
