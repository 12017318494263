import React from 'react';

import {
    Filter,
    SelectInput,
    SearchInput,
} from 'react-admin';

import { premierAccessFilterTypes } from './data';

const AgenciesFormFilter = (props) => {
    return (
        <Filter {...props}>
            <SearchInput alwaysOn source="query" />
            <SelectInput
                label="Premiere access"
                allowEmpty={false}
                source="premier_access"
                choices={premierAccessFilterTypes}
            />
        </Filter>
    );
};

export default AgenciesFormFilter;
