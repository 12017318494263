import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';

const TagsList = (props) => (
    <List
        {...props}
        title="Tags"
        sort={{ field: source.name, order: 'ASC' }}
        hasCreate={Permissions('tags', 'create') === true}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField
                label="Name"
                source={source.name}
            />
            <TextField
                label="Tag Usage"
                source={source.associationsCount}
            />
            <DateField
                label="Created At"
                source={source.createdAt}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <EditButtonCustom key="edit-btn" resource="tags" {...props} />
        </Datagrid>
    </List>
);

export default TagsList;
