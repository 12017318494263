import React from 'react';

import {
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';
import Filter from './Filter';

const RedirectsList = (props) => {
    return (
        <List
            {...props}
            title="Redirects"
            filters={<Filter />}
            sort={{ field: source.updatedAt, order: 'DESC' }}
            hasCreate={Permissions('redirects', 'create') === true}
            bulkActionButtons={<BulkActionButtons resource="redirects" {...props} />}
        >
            <Datagrid>
                <TextField
                    label="Source URL"
                    source={source.sourceUrl}
                />
                <TextField
                    label="Target URL"
                    source={source.targetUrl}
                />
                <DateField
                    label="Last Updated"
                    source={source.updatedAt}
                />
                <EditButtonCustom key="edit-btn" resource="redirects" {...props} />
            </Datagrid>
        </List>
    );
};

export default RedirectsList;
