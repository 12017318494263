import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    ReferenceField,
} from 'react-admin';

import {
    BulkActionButtons,
    EditButtonCustom,
} from '@src/custom';

import { source } from './data';

const CollateralRequestsList = (props) => (
    <List
        {...props}
        title="Collateral Requests"
        sort={{ field: source.updatedAt, order: 'DESC' }}
        hasCreate={false}
        bulkActionButtons={<BulkActionButtons resource="collateral_requests" {...props} />}
    >
        <Datagrid>
            <TextField
                label="First Name"
                source={source.firstName}
            />
            <TextField
                label="Last Name"
                source={source.lastName}
            />
            <TextField
                label="Email"
                source={source.email}
            />
            <ReferenceField
                label="Brand"
                source={source.collateralTemplateId}
                reference="collateral_templates"
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField
                label="Status"
                source={source.status}
            />
            <DateField
                label="Last Updated"
                source={source.updatedAt}
            />
            <EditButtonCustom key="edit-btn" resource="collateral_requests" {...props} />
        </Datagrid>
    </List>
);

export default CollateralRequestsList;
