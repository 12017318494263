import {
    mapTagsForOne,
} from './common';

function mapGetOneImageData(responseData) {
    return {
        ...responseData,
        ...mapTagsForOne(responseData),
    };
}

export { mapGetOneImageData };
