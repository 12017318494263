/* eslint-disable camelcase */

import castArray from 'lodash/castArray';

const cleanCommonParams = (params) => {
    const {
        id,
        created_at,
        updated_at,
        author,
        authorName,
        authorEmail,
        platform,
        content_images,
        record_revisions,
        ...rest
    } = params;

    return { ...rest };
};

const cleanTimestampsParams = (params) => {
    const { created_at, updated_at, ...rest } = params;

    return { ...rest };
};

const mapAttachmentParams = (isCreate, attachment) => {
    if (!attachment) return {};

    return { attachment_id: attachment.id };
};

const mapAttachmentsParams = (isCreate, attachments) => {
    if (!attachments) return {};

    return {
        attachment_ids: attachments.map((a) => a.id),
    };
};

const mapTagsParams = (isCreate, tags) => {
    if (!tags) return {};

    return {
        tag_ids: tags,
    };
};

const mapContentParams = (isCreate, content) => {
    if (!content) return {};

    if (content.id && isCreate) {
        return {
            content_attributes: { content: content.content },
        };
    }

    return {
        content_attributes: cleanTimestampsParams(content),
    };
};

const mapFeedItemParams = (isCreate, feedItem) => {
    if (!feedItem) return {};

    if (!feedItem.enabled) {
        return {
            feed_item_attributes: { _destroy: true },
        };
    }

    return {
        feed_item_attributes: {
            priority: feedItem.priority,
            channels: feedItem.channels,
        },
    };
};

const mapImageParams = (isCreate, image) => {
    if (!image) {
        return {
            image_id: null,
        };
    }

    return {
        image_id: image.id,
    };
};

const mapSecondImageParams = (isCreate, secondImage) => {
    if (!secondImage) {
        return {
            second_image_id: null,
        };
    }

    return {
        second_image_id: secondImage.id,
    };
};

const mapVideoParams = (isCreate, video) => {
    if (!video) {
        return {
            video_id: null,
        };
    }

    return {
        video_id: video.id,
    };
};

const mapProductsParams = (_isCreate, products) => {
    if (!products) return {};
    products = castArray(products);

    return {
        product_ids: products,
    };
};

const mapSalesChannelsParams = (_isCreate, salesChannels) => {
    if (!salesChannels) return {};

    return {
        sales_channel_ids: salesChannels,
    };
};

const mapStatesParams = (_isCreate, states) => {
    if (!states) return {};
    states = castArray(states);

    return {
        state_ids: states.filter((s) => s !== 'all-states'),
    };
};

const mapBrandsParams = (_isCreate, brands) => {
    if (!brands) return {};
    brands = castArray(brands);

    return {
        brand_ids: brands,
    };
};

const mapVideoRequestBrandsParams = (_isCreate, requestBrands) => {
    if (!requestBrands) return {};

    return {
        co_branded_video_request_brands_attributes: requestBrands.map((rb) => {
            return {
                id: rb.id,
                agency_logo_name_included: rb.agency_logo_name_included,
                agency_name: rb.agency_name,
                agency_phone_number: rb.agency_phone_number,
                agency_phone_number_enabled: rb.agency_phone_number_enabled,
                agency_email: rb.agency_email,
                agency_email_enabled: rb.agency_email_enabled,
                agency_website: rb.agency_website,
                agency_website_enabled: rb.agency_website_enabled,
                agency_logo_size_percentage: rb.agency_logo_size_percentage,
                license_number: rb.license_number,
                image_id: rb.agency_logo?.id,
                co_branded_video_template_ids: rb.co_branded_video_template_ids,
                co_branded_video_logo_id: rb.co_branded_video_logo_id,
            };
        }),
    };
};

const mapCollateralRequestTemplatesParams = (_isCreate, requestTemplates) => {
    if (!requestTemplates) return {};

    return {
        collateral_request_templates_attributes: requestTemplates.map((rt) => {
            return {
                id: rt.id,
                collateral_template_id: rt.collateral_template_id,
                // add another
            };
        }),
    };
};

const mapProductStatesParams = (isCreate, productStates) => {
    if (!productStates) return {};

    return {
        product_states_attributes: productStates.map((ps) => {
            return {
                id: ps.id,
                availability_map_status: ps.availabilityMapStatus,
                product_available: ps.productAvailable,
            };
        }),
    };
};

export {
    cleanCommonParams,
    cleanTimestampsParams,
    mapAttachmentParams,
    mapAttachmentsParams,
    mapTagsParams,
    mapContentParams,
    mapFeedItemParams,
    mapImageParams,
    mapSecondImageParams,
    mapVideoParams,
    mapProductsParams,
    mapSalesChannelsParams,
    mapStatesParams,
    mapBrandsParams,
    mapProductStatesParams,
    mapVideoRequestBrandsParams,
    mapCollateralRequestTemplatesParams,
};
