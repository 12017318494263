import React from 'react';
import PropTypes from 'prop-types';

import {
    TextInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

import ResourceInput from './resource-input';

import Grid from '@material-ui/core/Grid';

import { source } from './data';

const TagForm = () => {
    return (
        <Grid container >
            <Grid item xs={12}>
                <TextInput
                    fullWidth
                    required
                    label="Name"
                    source={source.name}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <ArrayInput label="Tagged Content" source={source.resources}>
                            <SimpleFormIterator disableAdd disableRemove>
                                <ResourceInput />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

TagForm.propTypes = {
    resource: PropTypes.string.isRequired,
};

export default TagForm;
