export const source = {
    id: 'id',
    alt: 'alt',
    associationsCount: 'associations_count',
    bytes: 'bytes',
    createdAt: 'created_at',
    expiredAt: 'expired_at',
    format: 'format',
    folder: 'folder',
    height: 'height',
    resources: 'resources',
    source: 'source',
    updatedAt: 'updated_at',
    width: 'width',
    tags: 'tags',
    tagsReference: 'tags',
};

export const resourceSource = {
    id: 'id',
    discardedAt: 'discarded_at',
    type: 'type',
    kind: 'kind',
    title: 'title',
};

export const filterFolder = [
    { id: 'featured', name: 'Featured' },
    { id: 'thumbnail', name: 'Thumbnail' },
    { id: 'content', name: 'Content' },
];
