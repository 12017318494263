import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import { uploadAndCreateVideo } from '@src/services/api/videos';
import { formatBytes } from '@src/utils/number-helpers';
import Dropzone from './video_uploader_dropzone';
import Actions from './video_uploader_actions';

const useStyles = makeStyles({
    media: {
        backgroundSize: 'contain',
        objectFit: 'cover',
        width: '100%',
    },
});

const VideoUploader = (props) => {
    const { folderName, onCancel, onVideoUploaded } = props;

    const classes = useStyles();

    const [videoBlob, setVideoBlob] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileSelection = useCallback((videoFile) => {
        setVideoFile(videoFile);

        const reader = new FileReader();
        reader.onabort = () => {
            setVideoBlob(null);
            setError('file reading was aborted');
        };

        reader.onerror = () => {
            setVideoBlob(null);
            setError('file reading has failed');
        };

        reader.onload = () => {
            setVideoBlob(reader.result);
            setError(null);
        };

        reader.readAsDataURL(videoFile);
    }, []);

    const handleVideoUploadError = useCallback((error) => {
        let errorMessage = error.messsage;

        const responseMessage = get(error, ['response', 'data', 'error', 'message']);
        if (responseMessage) {
            errorMessage = responseMessage.replace(
                /(\d+(?:((?=\.))\d*))/gm,
                (match) => formatBytes(match),
            );
        }

        setIsUploading(false);
        setVideoBlob(null);
        setError(errorMessage);
    }, [setIsUploading, setVideoBlob, setError]);

    const handleVideoUploadSuccess = useCallback((video) => {
        setIsUploading(false);
        setError(null);

        onVideoUploaded(video);
    }, [setIsUploading, setError, onVideoUploaded]);

    const handleVideoSave = useCallback(async (file) => {
        setIsUploading(true);
        await uploadAndCreateVideo(file, folderName)
            .then((video) => {
                handleVideoUploadSuccess(video);
            }).catch((error) => {
                handleVideoUploadError(error);
            });
    }, [folderName, setIsUploading, handleVideoUploadSuccess, handleVideoUploadError]);

    const handleSaveClick = useCallback((e) => {
        e.preventDefault();

        if (videoFile) {
            handleVideoSave(videoFile);
        } else {
            // eslint-disable-next-line no-alert
            alert('No Video File Selected');
        }
    }, [videoFile, handleVideoSave]);

    return (
        <Card>
            { videoBlob ? null : <Dropzone onFileSelected={handleFileSelection} /> }

            <Grid container className="" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    {

                        isUploading && (
                            <div className="upload-indication">
                                <img className="loading" src="/img/spinner.gif" height={20} />
                                <Typography variation="caption" align="center" color="primary">
                                    Uploading... Please wait.
                                </Typography>
                            </div>
                        )
                    }
                    {
                        videoBlob &&
                            <Paper>
                                <video className={classes.media}>
                                    <source src={URL.createObjectURL(videoFile)} />
                                </video>
                            </Paper>
                    }
                    {
                        error &&
                            <Typography variation="caption" align="center" color="error">
                                {error}
                            </Typography>
                    }
                </Grid>
            </Grid>

            <CardActions>
                <Actions
                    isFileSelected={Boolean(videoBlob)}
                    isUploading={isUploading}
                    onSave={handleSaveClick}
                    onCancel={onCancel}
                />
            </CardActions>
        </Card>
    );
};

VideoUploader.propTypes = {
    folderName: PropTypes.oneOf(['co_branded_videos']).isRequired,
    onCancel: PropTypes.func.isRequired,
    onVideoUploaded: PropTypes.func,
};

export default React.memo(VideoUploader);
