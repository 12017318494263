import React from 'react';

import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    TextField,
} from 'react-admin';

import { Permissions } from '@src/global/permissions';

import {
    BulkActionButtons,
    EditButtonCustom,
    CapitalizedTextField,
    ExpirationDateField,
    SingleFieldListCustom,
} from '@src/custom';

import FilterForm from './FilterForm';

import { source } from './data';

const AlertsList = (props) => {
    return (
        <List
            {...props}
            title="Alerts"
            filters={<FilterForm />}
            sort={{ field: source.updatedAt, order: 'DESC' }}
            hasCreate={Permissions('alerts', 'create') === true}
            bulkActionButtons={<BulkActionButtons resource="alerts" {...props} />}
        >
            <Datagrid>
                <TextField
                    label="Title"
                    source="title"
                />
                <TextField
                    label="Category"
                    source={source.category}
                />
                <ArrayField
                    label="Sales Channels"
                    source={source.salesChannels}
                    sortable={false}
                >
                    <SingleFieldListCustom linkType={false}>
                        <TextField source="id" />
                    </SingleFieldListCustom>
                </ArrayField>
                <DateField
                    label="Effective From"
                    source={source.effectiveFrom}
                />
                <DateField
                    label="Effective Until"
                    source={source.effectiveUntil}
                />
                <DateField
                    label="Archived At"
                    source={source.archivedAt}
                />
                <DateField
                    label="Creation Date"
                    source={source.createdAt}
                />
                <DateField
                    label="Last Updated"
                    source={source.updatedAt}
                />
                <ExpirationDateField
                    label="Expiration Date"
                    source={source.expiredAt}
                />
                <CapitalizedTextField
                    label="Status"
                    source={source.status}
                />
                <EditButtonCustom key="edit-btn" resource="alerts" {...props} />
            </Datagrid>
        </List>
    );
};

export default AlertsList;
