import { Taxonomies } from '@src/global/taxonomies';
import {
    mapAttachmentsForOne,
    mapAuthorForOne,
    mapFeedItem,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapStatesForList,
    mapStatesForOne,
    mapTagsForOne,
} from './common';

function mapCategories(responseData) {
    if (!responseData.categories) {
        return responseData.categories;
    }

    return {
        categories: responseData.categories.sort((a, b) => (a.name > b.name) ? 1 : -1),
        salesAndMarketingCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.salesAndMarketing)
            .map((item) => item.slug),
        systemCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.systemAndTechnical)
            .map((item) => item.slug),
        productCategories: responseData.categories
            .filter((category) => category.taxonomy === Taxonomies.productNewsAndUpdates)
            .map((item) => item.slug),
    };
}

function mapGetOneJobAidData(responseData) {
    return {
        ...responseData,
        ...mapCategories(responseData),
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapAttachmentsForOne(responseData),
        ...mapFeedItem(responseData),
        ...mapAuthorForOne(responseData),
        ...mapTagsForOne(responseData),
    };
}

function mapGetListJobAidsData(responseData) {
    return responseData.map((jobAid) => ({
        ...jobAid,
        ...mapCategories(jobAid),
        ...mapProductsForList(jobAid),
        ...mapStatesForList(jobAid),
    }));
}

export { mapGetOneJobAidData, mapGetListJobAidsData };
