import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    ProductStatesSelect,
    StyledSelectArrayInput,
    TagsInput,
} from '@src/custom';
import { status } from '@src/global/choices';
import { source } from './data';

import { helperText } from '@src/global/helperText';

const HomeLinkForm = (props) => {
    const { resource, record } = props;
    return (
        <>
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <AddToHomepageField resource={resource} source={source} />
            </SecondRow>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Title"
                        source={source.title}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="External URL"
                        source={source.externalUrl}
                        helperText={helperText.URL}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item xs={12}>
                    <TagsInput resource={resource} record={record} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ReferenceArrayInput
                        required
                        label="Sales Channel"
                        source={source.salesChannels}
                        reference={source.salesChannelsReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        fullWidth
                        required
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

HomeLinkForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default HomeLinkForm;
