import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    DateInput,
    ReferenceArrayInput,
    SelectInput,
    TextInput,
} from 'react-admin';
import { Field } from 'react-final-form';

import { FirstRow, SecondRow } from '@src/components/FormHeader';

import {
    AttachmentInput,
    AddToHomepageField,
    ExpirationDateInput,
    PublicationDateInput,
    FeedInputs,
    ProductStatesSelect,
    StyledSelectArrayInput,
    TitleSlug,
    TagsInput,
} from '@src/custom';

import { status } from '@src/global/choices';
import { source } from './data';

const RateAndRuleManualForm = (props) => {
    const { isCreate, resource, record } = props;
    return (
        <>
            <Field
                type="hidden"
                name={source.resourceType}
                component="input"
            />
            <FirstRow key="first-row" {...props} source={source}>
                <ExpirationDateInput resource={resource} source={source} />
                <PublicationDateInput resource={resource} source={source} />
                <SelectInput
                    source={source.status}
                    choices={status}
                />
            </FirstRow>
            <SecondRow key="second-row">
                <AddToHomepageField resource={resource} source={source} />
                <FeedInputs resource={resource} />
            </SecondRow>
            <TitleSlug
                required
                source={source}
                isCreate={isCreate}
                pageUrlPart="guides-and-rate-bulletins"
                titleLabel="Title"
            />
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Description"
                        source={source.description}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <TextInput
                        fullWidth
                        label="Internal Note"
                        source={source.note}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TagsInput resource={resource} record={record} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} >
                    <AttachmentInput
                        isRequired
                        fullWidth
                        hideThumbnail
                        source={source.attachment}
                        label="Attachment"
                        accept={{
                            'application/pdf': ['.pdf'],
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <ReferenceArrayInput
                        required
                        label="Writing Companies"
                        source={source.writingCompanies}
                        reference={source.writingCompaniesReference}
                        resource={resource}
                    >
                        <StyledSelectArrayInput fullWidth />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DateInput
                        fullWidth
                        required
                        label="New Business Effective Date"
                        source={source.businessEffectiveDate}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DateInput
                        fullWidth
                        required
                        label="Renewal Effective Date"
                        source={source.renewalEffectiveDate}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        required
                        label="Applicable Products"
                        reference={source.productsReference}
                        source={source.products}
                        resource={resource}
                        // eslint-disable-next-line camelcase
                        filter={{ for_guides: true }}
                    >
                        <StyledSelectArrayInput fullWidth groupBy="business_unit_name" />
                    </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductStatesSelect
                        fullWidth
                        required
                        label="Applicable States"
                        source={source.states}
                        productsSource={source.products}
                        resource={resource}
                    />
                </Grid>
            </Grid>
        </>
    );
};

RateAndRuleManualForm.propTypes = {
    isCreate: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default React.memo(RateAndRuleManualForm);
