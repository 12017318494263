import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {
    ArrayInput,
    DateInput,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';

import {
    TextCopyInput,
    ExpirationDateInput,
    TagsInput,
} from '@src/custom';
import { formatBytes } from '@src/utils/number-helpers';

import { source } from './data';
import ImagePreviewInput from './image-preview-input';
import ResourceInput from './resource-input';

const ImageForm = (props) => {
    const { resource, record } = props;

    return (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <ExpirationDateInput resource={resource} source={source} />
                </Grid>
                <Grid item>
                    <DateInput
                        label="Uploaded On"
                        source={source.createdAt}
                        inputProps={{ readOnly: true }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        required
                        label="Alternate text"
                        source={source.alt}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextCopyInput
                        fullWidth
                        required
                        copyButtonTitle="Copy URL"
                        source={source.source}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TagsInput resource={resource} record={record} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ImagePreviewInput source={source.source} />
                </Grid>
                <Grid container item xs={12} md={6}>
                    <Grid item xs={3}>
                        <TextInput label="File type" source={source.format} inputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput source={source.width} inputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput source={source.height} inputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput label="Size" source={source.bytes} format={formatBytes} inputProps={{ readOnly: true }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ArrayInput label="Attached to" source={source.resources}>
                            <SimpleFormIterator disableAdd disableRemove>
                                <ResourceInput />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

ImageForm.propTypes = {
    resource: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default ImageForm;
