import {
    mapTagsForOne,
} from './common';

function mapGetOneAttachmentData(responseData) {
    return {
        ...responseData,
        ...mapTagsForOne(responseData),
    };
}

export { mapGetOneAttachmentData };
