import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import castArray from 'lodash/castArray';
import uniqBy from 'lodash/uniqBy';
import { Labeled, useInput } from 'react-admin';

import Item from './attachment-item';
import Selector from './attachment-selector';
import Uploader from './attachment-uploader';

const AttachmentsInput = ({
    accept,
    fullWidth = true,
    helperText,
    itemLabel,
    label = 'Attachments',
    hideThumbnail,
    ...props
}) => {
    const { input, meta, isRequired } = useInput(props);

    const handleAttachmentsAdded = useCallback((attachments) => {
        attachments = castArray(attachments);
        const newAttachments = uniqBy([
            ...(input.value || []),
            ...attachments,
        ], 'id');

        input.onChange(newAttachments);
        input.onBlur();
    }, [input]);

    const handleAttachmentRemoved = useCallback((attachment) => {
        const filteredAttachments = (input.value || []).filter((a) => a.id !== attachment.id);

        input.onChange(filteredAttachments);
        input.onBlur();
    }, [input]);

    const handleAttachmentUpdated = useCallback((attachment) => {
        const filteredAttachments = (input.value || []).map((a) => a.id === attachment.id ? attachment : a);

        input.onChange(filteredAttachments);
        input.onBlur();
    }, [input]);

    return (
        <Labeled
            fullWidth={fullWidth}
            label={label}
            isRequired={isRequired}
            meta={meta}
            input={input}
            resource={props.resource}
            source={props.source}
        >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Uploader accept={accept} onAttachmentsAdded={handleAttachmentsAdded} />
                </Grid>
                <Grid item xs={12}>
                    <Selector onAttachmentSelected={handleAttachmentsAdded} />
                </Grid>
                {
                    (input.value || []).map((attachment, index) => {
                        const source = `${props.source}.${index}`;

                        return (
                            <Grid key={source} item xs={12}>
                                <Item
                                    attachment={attachment}
                                    label={itemLabel}
                                    hideThumbnail={hideThumbnail}
                                    onAttachmentRemoved={handleAttachmentRemoved}
                                    onAttachmentUpdated={handleAttachmentUpdated}
                                />
                            </Grid>
                        );
                    })
                }
                { meta.touched && meta.error && <Grid item xs={12}><FormHelperText>{meta.error}</FormHelperText></Grid> }
                { helperText && <Grid item xs={12}><FormHelperText>{helperText}</FormHelperText></Grid> }
            </Grid>
        </Labeled>
    );
};

AttachmentsInput.propTypes = {
    accept: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    itemLabel: PropTypes.string,
    label: PropTypes.string,
    hideThumbnail: PropTypes.bool,
    resource: PropTypes.string,
    source: PropTypes.string,
};

export default React.memo(AttachmentsInput);
