import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.title)) {
        addError(errors, source.title, 'A Title is required');
    }

    if (!values[source.slug]) {
        errors[source.slug] = ['An URL is required'];
    }

    return errors;
};

