import get from 'lodash/get';

import { addError } from '@src/validations';

import { source } from './data';

export const validate = (values) => {
    const errors = {};

    if (!get(values, source.firstName)) {
        addError(errors, source.firstName, 'A First Name is required');
    }

    if (!get(values, source.lastName)) {
        addError(errors, source.lastName, 'A Last Name is required');
    }

    if (!get(values, source.requesterEmail)) {
        addError(errors, source.requesterEmail, 'A Requester Email is required');
    }

    if (!get(values, source.stateId)) {
        addError(errors, source.stateId, 'A State is required');
    }

    if (!get(values, source.status)) {
        addError(errors, source.status, 'A Status is required');
    }

    if (!get(values, source.foremostProducerCode) && !get(values, source.bristolProducerCode)) {
        addError(errors, source.foremostProducerCode, 'A Producer Code is required');
    }

    if (get(values, source.foremostProducerCode) && get(values, source.foremostProducerCode).length < 7) {
        addError(
            errors,
            source.foremostProducerCode,
            'A Foremost producer code is too short (minimum is 7 characters)',
        );
    }

    if (get(values, source.foremostProducerCode) && get(values, source.foremostProducerCode).length > 9) {
        addError(
            errors,
            source.foremostProducerCode,
            'A Foremost producer code is too long (maximum is 9 characters)',
        );
    }

    if (get(values, source.bristolProducerCode) && get(values, source.bristolProducerCode).length !== 7) {
        addError(
            errors,
            source.bristolProducerCode,
            'A Bristol producer code can only contain numbers and code is the wrong length (should be 7 characters)',
        );
    }

    if (values[source.coBrandedVideoRequestBrands] !== undefined) {
        values[source.coBrandedVideoRequestBrands].map((rb, index) => {
            const sizePercentage = get(values, `co_branded_video_request_brands.${index}.agency_logo_size_percentage`);
            if (sizePercentage > 200) {
                addError(errors, `co_branded_video_request_brands.${index}.agency_logo_size_percentage`, 'Max 200');
            }

            if (sizePercentage < 0) {
                addError(errors, `co_branded_video_request_brands.${index}.agency_logo_size_percentage`, 'Min 0');
            }

            const licNumber = get(values, `co_branded_video_request_brands.${index}.license_number`);
            const state = get(values, source.stateId);
            if (state === 'ca' && !licNumber) {
                addError(errors, `co_branded_video_request_brands.${index}.license_number`, 'A License Number is required');
            }

            return null;
        });
    }

    return errors;
};

