import {
    mapAuthorForOne,
    mapProductsForList,
    mapProductsForOne,
    mapSalesChannelsForOne,
    mapStatesForList,
    mapStatesForOne,
    mapTagsForOne,
} from './common';

function mapGetOneHomeLinkData(responseData) {
    return {
        ...responseData,
        ...mapSalesChannelsForOne(responseData),
        ...mapProductsForOne(responseData),
        ...mapStatesForOne(responseData),
        ...mapAuthorForOne(responseData),
        ...mapTagsForOne(responseData),
    };
}

function mapGetListHomeLinksData(responseData) {
    return responseData.map((homeLink) => ({
        ...homeLink,
        ...mapProductsForList(homeLink),
        ...mapStatesForList(homeLink),
    }));
}

export { mapGetOneHomeLinkData, mapGetListHomeLinksData };
