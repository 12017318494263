import { stringify } from 'query-string';

import { BASE_API_URL } from '@src/services/api/helpers';

const concatQueryCategoryIds = (query) => {
    const categoryIds = [].concat(
        query.salesAndMarketingCategories || [],
        query.systemCategories || [],
        query.productCategories || [],
        query.collateralCategories || [],
    );

    if (categoryIds.length > 0) {
        query['category_ids'] = categoryIds;
    }

    delete query.salesAndMarketingCategories;
    delete query.systemCategories;
    delete query.productCategories;
    delete query.collateralCategories;
};

const getGetListURL = (resource, rawResource, params) => {
    // console.log('resource: ', resource);
    // console.log('rawResource: ', rawResource);
    // console.log('params: ', params);
    const { page, perPage } = params.pagination;

    const query = {
        ...params.filter,
        perPage,
        page,
    };

    if (params.sort && params.sort.field !== 'id') {
        query['sort_by'] = params.sort.field;
        query['order_by'] = params.sort.order;
    }

    switch (rawResource) {
    case 'all_products':
        resource = 'products/all';
        break;
    case 'admins':
        query['access_level'] = ['admin'];
        break;
    case 'employees':
        query['access_level'] = ['home_office_employee'];
        break;
    case 'agents':
        if (query['access_level'] && ['agent', 'manager'].includes(query['access_level'])) {
            query['access_level'] = [query['access_level']];
        } else {
            query['access_level'] = ['agent', 'manager'];
        }

        break;
    case 'eligibility_guides':
        query['resource_types'] = ['EligibilityGuide'];
        break;
    case 'rate_bulletins':
        query['resource_types'] = ['RateAndRuleManual'];
        break;
    case 'underwriting_guidelines':
        query['resource_types'] = ['UnderwritingGuideline'];
        break;
    case 'quote_inquiries':
        resource = 'form_entries/quote_inquiries';
        break;
    case 'rsm_agent360':
        resource = 'simple_pages/rsm_agent360';
        break;
    case 'collaterals':
    case 'job_aids':
    case 'trainings':
    case 'webinars':
        concatQueryCategoryIds(query);
        break;
    default:
        break;
    }

    return `${BASE_API_URL}/${resource}?${stringify(query, { arrayFormat: 'bracket' })}`;
};

const getGetOneURL = (resource, params) => {
    switch (resource) {
    case 'all_products':
        resource = 'products';
        break;
    case 'quote_inquiries':
        resource = 'form_entries';
        break;
    default:
        break;
    }

    return `${BASE_API_URL}/${resource}/${params.id}`;
};

const getGetManyURL = (resource, params) => {
    const paramsIds = params.ids;
    let paramsIDString;

    if (typeof paramsIds[0] === 'string') {
        paramsIDString = paramsIds.map((item) => `slugs[]=${item}`).join('&');
    } else if (typeof paramsIds[0] === 'number') {
        paramsIDString = paramsIds.map((item) => `ids[]=${item}`).join('&');
    } else {
        paramsIDString = paramsIds.map((item) => `slugs[]=${item.slug ? item.slug : item.id}`).join('&');
    }

    return `${BASE_API_URL}/${resource}?${paramsIDString}`;
};

const getCreateURL = (resource) => {
    return `${BASE_API_URL}/${resource}`;
};

const getUpdateURL = (resource, params) => {
    switch (resource) {
    case 'all_products':
        resource = 'products';
        break;
    default:
        break;
    }

    return `${BASE_API_URL}/${resource}/${params.id}`;
};

const getUpdatePositionURL = (resource, id) => {
    return `${BASE_API_URL}/${resource}/${id}/position`;
};

const getDeleteURL = (resource, params) => {
    switch (resource) {
    case 'all_products':
        resource = 'products';
        break;
    default:
        break;
    }

    return `${BASE_API_URL}/${resource}/${params.id}`;
};

const getUpdateManyURL = (resource, params) => {
    let updateUrl = '';

    switch (resource) {
    case 'users':
        if (params.data.premier_access === true) {
            updateUrl = 'grant_premier_access_all';
        } else {
            updateUrl = 'revoke_premier_access_all';
        }

        break;
    case 'agencies':
        if (params.data.premier_access === true) {
            updateUrl = 'grant_premier_access_all';
        } else {
            updateUrl = 'revoke_premier_access_all';
        }

        break;
    default:
        break;
    }

    const paramsIDString = params.ids.map((id) => `ids[]=${id}`).join('&');
    return `${BASE_API_URL}/${resource}/${updateUrl}?${paramsIDString}`;
};

const getDeleteManyURL = (resource, params) => {
    switch (resource) {
    case 'all_products':
        resource = 'products';
        break;
    default:
        break;
    }

    const paramsIDString = params.ids.map((id) => `ids[]=${id}`).join('&');
    return `${BASE_API_URL}/${resource}?${paramsIDString}`;
};

export {
    getGetListURL,
    getGetOneURL,
    getGetManyURL,
    getCreateURL,
    getUpdateURL,
    getUpdateManyURL,
    getDeleteURL,
    getDeleteManyURL,
    getUpdatePositionURL,
};
